<template>
    <div>
        <div class="flex-column justify-center align-items-center" style="height:100vh;width:100vw">
            <img :src="img"/>
            <div class="margin-top-20 margin-bottom-30">
                <el-button type="primary" size="small" @click="gotoHome">返回主页</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import img from "@/assets/images/default/404_cn.png";
export default {
    data() {
        return {
            img
        }
    },
    created() {
        
    },
    methods:{
        gotoHome(){
            this.$router.replace({
              path: '/',
          })
        }
    }
}
</script>
<style scoped>

</style>